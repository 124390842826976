import React from "react"
import "../styles/mindbody.scss"
import accountStyles from "../components/account.module.scss"
import Layout from '../components/layout-default'
import Breadcrumbs from "../components/breadcrumbs";

const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Teacher Training",
  },
];

const TeacherTraining = () => (
  <Layout
    title="Enroll in Bachata Basics Teacher Training - RF Dance"
    description="Start your path to becoming a Bachata Dance Trainer with RF Dance's Teacher Training course. Learn from experts and get ready to inspire others!" 
    pathname="account"
    noHero="no-hero">
    <div className="container">
      <Breadcrumbs crumbs={crumbs} />
      <iframe 
        className={accountStyles.iframe}
        title="RF Teacher Training"
        id="mindbody_branded_web_cart_modal" 
        height="800"
        frameBorder="0" 
        marginWidth="0" 
        marginHeight="0" 
        scrolling="yes"
        src="https://docs.google.com/forms/d/e/1FAIpQLScAIIvbiFu7mVLL1gX6ZtxcfSvTMqY9q-dMyOreGRWEn3lnIA/viewform" />
    </div>
  </Layout>
)

export default TeacherTraining

